import React, { useState } from "react";

import { useSelector } from "react-redux";

import {
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL,
  ASK_FOR_HELP,
  CANCEL,
  CANCEL_SUBSCRIPTION,
  CONFIRM,
  CONTACT_US_TITLE,
  E_MAIL,
  LOGOUT,
  PERSONAL_INFORMATION,
  PHONE_NUMBER,
  SOMETHING_WENT_WRONG,
  TRY_AGAIN,
  WE_COULDNT_PROCCESS_YOUR_REQUEST,
  YOU_WILL_LOSE_YOU,
} from "../../utils/strings";
import DashboardMenuBadge from "../DashboardMenuBadge";
import {
  CircleInfoYellow,
  EmailIconYellow,
  PhoneYellow,
} from "../../assets/images";
import theme from "../../utils/theme";
import { logoutFirebase } from "../../auth/operations";
import { cancelCurrentMembership } from "../../api/apiEndpoints";
import Modal from "../Modal";
import StatusScreen from "../StatusScreen";
import { Container, Title, Icon, Subtitle, TitleTwo } from "./styles";
import { HOME } from "../../utils/routes";
import { useNavigate } from "react-router-dom";

interface DashboardHeaderProps {
  avatar: string;
}

const DashboardModal = ({ avatar }: DashboardHeaderProps) => {
  const { firstName, lastName, email, phone } = useSelector(
    (state: RootState) => state.appData.userData
  );

  const navigate = useNavigate();

  const { memberLoggedIn } = useSelector((state: RootState) => state.auth);

  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [cancelError, setCancelError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogout = async () => {
    await logoutFirebase();
    navigate(HOME);
  };

  const handleCancelSubscription = async () => {
    setLoading(true);

    try {
      const result = await cancelCurrentMembership();
      if (result?.status === 200 || result?.status === 201) {
        setLoading(false);
        await logoutFirebase();
        setShowStatusModal(false);
        navigate(HOME);
      }
    } catch (error) {
      setLoading(false);
      setCancelError(true);
    }
  };

  const handleOpenStatusModal = () => {
    setShowStatusModal(!showStatusModal);
  };

  return (
    <Container>
      <Title>{PERSONAL_INFORMATION}</Title>
      <Icon src={avatar} alt="leftIcon" />
      <TitleTwo>
        {firstName} {lastName}
      </TitleTwo>
      <Subtitle>{email}</Subtitle>
      <DashboardMenuBadge
        title={E_MAIL}
        subtitle={email}
        leftIcon={EmailIconYellow}
        rightButton={false}
        rightCheckIcon
      />
      {memberLoggedIn && (
        <DashboardMenuBadge
          title={PHONE_NUMBER}
          subtitle={phone}
          leftIcon={PhoneYellow}
          rightButton={false}
          rightCheckIcon
        />
      )}
      {memberLoggedIn && (
        <DashboardMenuBadge
          title={CANCEL_SUBSCRIPTION}
          leftIcon={CircleInfoYellow}
          rightButton
          rightButtonText={CANCEL}
          marginTop="20px"
          handleButtonClick={handleOpenStatusModal}
        />
      )}
      <DashboardMenuBadge
        title={ASK_FOR_HELP}
        leftIcon={CircleInfoYellow}
        rightButton
        rightButtonText={CONTACT_US_TITLE}
        marginTop="20px"
        handleButtonClick={() =>
          (window.location.href = "mailto:Support@jackedrabbitgyms.com")
        }
      />
      <DashboardMenuBadge
        secondaryTitle={LOGOUT}
        marginTop="20px"
        secondaryTitleColor={theme.templateColors.lightYellow}
        handleClickTitleTwo={handleLogout}
      />
      <Modal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            showIcon={false}
            title={
              cancelError
                ? SOMETHING_WENT_WRONG
                : ARE_YOU_SURE_YOU_WANT_TO_CANCEL
            }
            description={
              cancelError ? WE_COULDNT_PROCCESS_YOUR_REQUEST : YOU_WILL_LOSE_YOU
            }
            showButton={true}
            successButtonTile={CONFIRM}
            failure={cancelError}
            loading={loading}
            onClose={handleOpenStatusModal}
            onButtonClick={handleCancelSubscription}
            useButtonEvent={true}
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default DashboardModal;
