import React, { useEffect, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import EOMButton from "../../../components/EOMButton";
import {
  EMAIL_ALREADY_IN_USE,
  ERROR_EMAIL_ALREADY_IN_USE,
  PARTNER_SIGNUP,
  SIGNUP,
  SOMETHING_WENT_WRONG,
  WE_COULDNT_PROCCESS,
} from "../../../utils/strings";
import { LogoLetters } from "../../../assets/images";
import { signUpFirebase } from "../../../auth/operations";
import theme from "../../../utils/theme";
import { DASHBOARD_GYM_OWNERS } from "../../../utils/routes";
import { emptyBasket } from "../../../slices/appSlice";
import { useApi } from "../../../hooks";
import { invitePartner } from "../../../api/apiEndpoints";
import StatusScreen from "../../../components/StatusScreen";
import Modal from "../../../components/Modal";
import {
  Container,
  CustomizedTextField,
  LogoStyle,
  CustomizedTextFieldEmail,
  Subcontainer,
  Title,
} from "./styles";

const SignUpGymOwner = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [signUpFirebaseError, setSignUpFirebaseError] =
    useState<boolean>(false);
  const [signUpFirebaseInvalidEmail, setSignUpFirebaseInvalidEmail] =
    useState<boolean>(false);

  let { tokenValue } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    request: invitePartnerReq,
    data: invitePartnerData,
    error: invitePartnerError,
  } = useApi<any>({
    apiFunc: invitePartner,
  });

  const handleSignup = async () => {
    setLoading(true);
    const newUserResult = await signUpFirebase(username, password, true);

    if (
      newUserResult === ERROR_EMAIL_ALREADY_IN_USE ||
      newUserResult !== "success"
    ) {
      setLoading(false);
      setSignUpFirebaseError(true);
      setSignUpFirebaseInvalidEmail(
        newUserResult === ERROR_EMAIL_ALREADY_IN_USE
      );
      setShowStatusModal(true);
    } else {
      setLoading(false);
      navigate(DASHBOARD_GYM_OWNERS);
    }
  };

  const handleOpenStatusModal = () => {
    setShowStatusModal(!showStatusModal);
  };

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!token) {
      if (!!tokenValue) {
        setToken(true);
        invitePartnerReq(tokenValue);
      }
    }
  }, [tokenValue]);

  useEffect(() => {
    setUsername(invitePartnerData?.email?.toLowerCase());
  }, [invitePartnerData]);

  useEffect(() => {
    if (invitePartnerError)
      alert("Something went wrong, please try again later.");
  }, [invitePartnerError]);

  return (
    <Container>
      <LogoStyle src={LogoLetters} />
      <Title>{PARTNER_SIGNUP}</Title>
      <Subcontainer>
        <CustomizedTextFieldEmail
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={username}
          disabled
          autoFocus
          style={{ WebkitTextFillColor: `${theme.colors.grey}` }}
        />
        <CustomizedTextField
          value={password}
          onChange={(e: any) => {
            setPassword(e.target.value);

            if (e.target.validity.valid) {
              setPassError(false);
            } else {
              setPassError(true);
            }
          }}
          label="Your New Password"
          placeholder="Enter Password"
          InputLabelProps={{
            shrink: true,
          }}
          autoFocus
          inputProps={{
            pattern: "^.{6,}$",
          }}
          FormHelperTextProps={{
            sx: {
              color: "red",
            },
          }}
          helperText={passError ? "Password must be 6 characters long" : ""}
          type={!showPassword ? "password" : "text"}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: theme.colors.grey }}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <EOMButton
          title={SIGNUP}
          onPress={handleSignup}
          disabled={!username || !password}
          buttonStyle={{
            borderRadius: 3,
            height: 15,
            marginTop: 60,
            alignContent: "center",
          }}
          loading={loading}
          titleStyle={{
            color: "black",
            fontSize: 14,
            textAlign: "center",
          }}
        />
        <Modal
          showModal={showStatusModal}
          onClose={handleOpenStatusModal}
          content={
            <StatusScreen
              title={SOMETHING_WENT_WRONG}
              description={
                signUpFirebaseInvalidEmail
                  ? EMAIL_ALREADY_IN_USE
                  : WE_COULDNT_PROCCESS
              }
              successButtonTile=""
              failure={signUpFirebaseError}
              showDescription
              onClose={handleOpenStatusModal}
            />
          }
          backgroundColor={theme.templateColors.lightDark}
          closeColor={theme.templateColors.lightYellow}
        />
      </Subcontainer>
    </Container>
  );
};

export default SignUpGymOwner;
