import {
  BigThreeIcon,
  ColdPlungeIcon,
  EliteEnergylogo,
  GroupClasessIcon,
  LockersIcon,
  NutritionBarIcon,
  PartnerAG1,
  PartnerInstantIv,
  PartnerSugared,
  PersonalTraining,
  RedLightIcon,
  SaunaIcon,
  ShowersIcon,
  SpinningIcon,
  SteamRoomIcon,
  SwimmingPoolIcon,
  TanningIcon,
  TwentyFourOpen,
} from "../assets/images";
import {
  HOW_IT_WORKS_STEP_ONE,
  HOW_IT_WORKS_STEP_ONE_DESC,
  HOW_IT_WORKS_STEP_ONE_DESC_MOBILE,
  HOW_IT_WORKS_STEP_THREE,
  HOW_IT_WORKS_STEP_THREE_DESC,
  HOW_IT_WORKS_STEP_THREE_DESC_MOBILE,
  HOW_IT_WORKS_STEP_TWO,
  HOW_IT_WORKS_STEP_TWO_DESC,
} from "./strings";

export const navbarTiles = [
  { id: "#gym-hop", name: "Gym Hop" },
  { id: "#become-a-partner", name: "Become a Partner" },
  { id: "#about", name: "About" },
  { id: "#contact", name: "Contact" },
];

export const howItWorksCardArr = [
  {
    title: HOW_IT_WORKS_STEP_ONE,
    description: HOW_IT_WORKS_STEP_ONE_DESC,
    descriptionMobile: HOW_IT_WORKS_STEP_ONE_DESC_MOBILE,
  },
  {
    title: HOW_IT_WORKS_STEP_TWO,
    description: HOW_IT_WORKS_STEP_TWO_DESC,
    descriptionMobile: HOW_IT_WORKS_STEP_TWO_DESC,
  },
  {
    title: HOW_IT_WORKS_STEP_THREE,
    description: HOW_IT_WORKS_STEP_THREE_DESC,
    descriptionMobile: HOW_IT_WORKS_STEP_THREE_DESC_MOBILE,
  },
];

export const gymCardsArr = [
  {
    id: "01",
    name: "Bombshell Fitness",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
      {
        name: "linkedin",
        link: "https://www.instagram.com/bombshellfitness/",
      },
      {
        name: "youtube",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "02",
    name: "Bombshell Fitness 2",
    gallery: [
      "https://images.pexels.com/photos/28080/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/949132/pexels-photo-949132.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/2827400/pexels-photo-2827400.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "03",
    name: "Bombshell Fitness 3",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "04",
    name: "Bombshell Fitness 4",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "05",
    name: "Bombshell Fitness 5",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "06",
    name: "Bombshell Fitness 6",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "07",
    name: "Bombshell Fitness 7",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "08",
    name: "Bombshell Fitness 8",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
  {
    id: "09",
    name: "Bombshell Fitness 9",
    gallery: [
      "https://images.pexels.com/photos/1552106/pexels-photo-1552106.jpeg?auto=compress&cs=tinysrgb&w=800",
      "https://images.pexels.com/photos/416717/pexels-photo-416717.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4162449/pexels-photo-4162449.jpeg?auto=compress&cs=tinysrgb&w=800",
    ],
    description:
      "Bombshell Fitness provides monthly, customized nutrition, cardio, and workout plans, uniquely tailored to fit your goals, fitness level, and lifestyle.",
    amenities: [
      "Cold Plunge",
      "24/7 Access",
      "Tanning",
      "Showers",
      "Nutrition Bar",
    ],
    address: {
      fullAddress: "1657 N Miami Ave, Miami, FL 33136, United States",
    },
    socialLinks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/bombshellfitness/",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/bombshellfitness/",
      },
    ],
    price: "300",
  },
];

export const gymUsersArr = [
  {
    name: "Jhon Levy ",
    email: "jhonperezsevilla@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "active",
    membershipType: "basic",
  },
  {
    name: "Jhon Levy 2",
    email: "jhon@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "active",
    membershipType: "upgraded",
  },
  {
    name: "Jhon Levy 3 ",
    email: "jhon@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "suspended",
    membershipType: "basic",
  },
  {
    name: "Jhon Levy 4 ",
    email: "jhon@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "active",
    membershipType: "upgraded",
  },
  {
    name: "Jhon Levy 4 ",
    email: "jhon@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "active",
    membershipType: "upgraded",
  },
  {
    name: "Jhon Levy 4 ",
    email: "jhon@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "active",
    membershipType: "upgraded",
  },
  {
    name: "Jhon Levy 4 ",
    email: "jhon@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "active",
    membershipType: "upgraded",
  },
  {
    name: "Jhon Levy 4 ",
    email: "jhon@gmail",
    membershipExpiration: "22 days left",
    accountStatus: "active",
    membershipType: "upgraded",
  },
];

export const partnersLogos = [
  { name: "Sugared & Bronzed", logo: PartnerSugared },
  { name: "Instant IV", logo: PartnerInstantIv },
  { name: "AG1", logo: PartnerAG1 },
  { name: "Elite Energy", logo: EliteEnergylogo },
];

export const allAmenitiesArr = [
  { amenityName: "Big 3", icon: BigThreeIcon },
  { amenityName: "Cold Plunge", icon: ColdPlungeIcon },
  { amenityName: "Group classes", icon: GroupClasessIcon },
  { amenityName: "Lockers", icon: LockersIcon },
  { amenityName: "Nutrition Bar", icon: NutritionBarIcon },
  { amenityName: "Personal training", icon: PersonalTraining },
  { amenityName: "Red Light Therapy", icon: RedLightIcon },
  { amenityName: "Sauna", icon: SaunaIcon },
  { amenityName: "Showers", icon: ShowersIcon },
  { amenityName: "Spinning", icon: SpinningIcon },
  { amenityName: "Steam Room", icon: SteamRoomIcon },
  { amenityName: "Swimming pool", icon: SwimmingPoolIcon },
  { amenityName: "Tanning", icon: TanningIcon },
  { amenityName: "24/7 Access", icon: TwentyFourOpen },
];
