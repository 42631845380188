import React, { useEffect, useRef } from "react";

import { useMediaQuery } from "react-responsive";
import { HashLink as Link } from "react-router-hash-link";

import { HeroVideo, HeroVideoMobile } from "../../../assets/videos";
import {
  HERO_BUTTON,
  HERO_SUBTITLE,
  HERO_SUBTITLE_MOBILE_1,
  HERO_SUBTITLE_MOBILE_2,
  HERO_SUBTITLE_MOBILE_3,
  HERO_TITLE,
} from "../../../utils/strings";
import EOMButton from "../../../components/EOMButton";
import theme from "../../../utils/theme";
import { isTabletOrMobileSize } from "../../../utils/queries";
import {
  Container,
  Video,
  VideoDiv,
  IntroContainer,
  Title,
  SubTitle,
  Overlay,
  SubTitleBullet,
  SubtitleBox,
  ImageContainer,
  Image,
} from "./styles";
import { HeroImageMobile, HeroImageDesktop } from "../../../assets/images";

const HeroSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });
  // const isMobile = useMediaQuery({ query: isMobileSize });
  // const videoContainer = useRef<any>(null);

  // *****
  // This video implementation will live here, in case the client want to have a video as hero section again.

  // useEffect(() => {
  //   const video = document.createElement("video");
  //   video.autoplay = true;
  //   video.loop = true;
  //   video.muted = true; // fixes autoplay in Chrome
  //   video.setAttribute("playsinline", "true"); // fixes autoplay in WebKit (e.g., mobile Safari)
  //   video.controls = false; // Ensure controls are disabled

  //   const source = document.createElement("source");
  //   source.src = isMobile ? HeroVideoMobile : HeroVideo;
  //   source.type = "video/mp4";
  //   video.appendChild(source);

  //   if (videoContainer.current) {
  //     videoContainer.current.appendChild(video);
  //   }

  //   // Attempt autoplay and handle user interaction if autoplay fails
  //   const attemptAutoplay = async () => {
  //     try {
  //       await video.play();
  //     } catch (err) {
  //       console.log("Autoplay failed, waiting for user interaction", err);
  //     }
  //   };

  //   attemptAutoplay();

  //   const handleUserInteraction = () => {
  //     attemptAutoplay();
  //     document.removeEventListener("click", handleUserInteraction);
  //     document.removeEventListener("touchstart", handleUserInteraction);
  //   };

  //   document.addEventListener("click", handleUserInteraction);
  //   document.addEventListener("touchstart", handleUserInteraction);

  //   return () => {
  //     document.removeEventListener("click", handleUserInteraction);
  //     document.removeEventListener("touchstart", handleUserInteraction);
  //   };
  // }, []);

  return (
    <Container id="hero">
      {/* This video implementation will live here, in case the client want to have a video as hero section again. */}
      {/* <VideoDiv ref={videoContainer} /> */}
      <ImageContainer>
        <Image src={isTabletOrMobile ? HeroImageMobile : HeroImageDesktop} />
      </ImageContainer>
      <Overlay />
      <IntroContainer>
        <Title>{HERO_TITLE}</Title>
        {isTabletOrMobile ? (
          <SubtitleBox>
            <SubTitleBullet>• {HERO_SUBTITLE_MOBILE_1}</SubTitleBullet>
            <SubTitleBullet>• {HERO_SUBTITLE_MOBILE_2}</SubTitleBullet>
            <SubTitleBullet>• {HERO_SUBTITLE_MOBILE_3}</SubTitleBullet>
          </SubtitleBox>
        ) : (
          <SubTitle>{HERO_SUBTITLE}</SubTitle>
        )}
        <Link
          to={"#gym-hop"}
          style={{ textDecoration: "none", zIndex: 550, alignSelf: "center" }}
        >
          <EOMButton
            title={HERO_BUTTON}
            onPress={() => null}
            buttonStyle={{
              backgroundColor: theme.templateColors.lightYellow,
              borderRadius: 3,
              width: isTabletOrMobile ? 226 : 200,
              marginTop: 20,
            }}
            titleStyle={{
              color: theme.templateColors.secondaryGrey,
              fontSize: isTabletOrMobile ? 13 : 16,
              textAlign: "center",
              fontWeight: 500,
            }}
          />
        </Link>
      </IntroContainer>
    </Container>
  );
};

export default HeroSection;
