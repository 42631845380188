import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useLogPageEvent = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "PageView", {
        page: location.pathname,
        url: window.location.href,
      });
      console.log("Logged PageView for:", location.pathname); // Optional: Debugging
    }
  }, [location]);
};

export default useLogPageEvent;
