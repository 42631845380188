import React from "react";

import { useSelector } from "react-redux";
import { Routes as RoutesDom, Route, Navigate } from "react-router-dom";

import * as routes from "../utils/routes";
import Home from "../features/app/Home";
import Checkout from "../features/app/Checkout";
import DashboardMembers from "../features/app/DashboardMembers";
import DashboardGymOwners from "../features/app/DashboardGymOwners";
import SignUpGymOwner from "../features/auth/SignUpGymOwner";
import LoginTesting from "../features/auth/Testing/LoginTesting";
import useLogPageEvent from "../utils/hooks";

export const ProtectedRouteMembers = ({ children }: any) => {
  const { loggedIn, memberLoggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  if (!loggedIn) {
    return <Navigate to={routes.HOME} />;
  }
  if (memberLoggedIn) {
    return children;
  }
};

export const ProtectedRouteGyms = ({ children }: any) => {
  const { loggedIn, gymOwnerLoggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  if (!loggedIn) {
    return <Navigate to={routes.HOME} />;
  }
  if (gymOwnerLoggedIn) {
    return children;
  }
};

export const ProtectedRouteCheckout = ({ children }: any) => {
  const { userExist, membershipUpgraded } = useSelector(
    (state: RootState) => state.appData
  );
  const { loggedIn, gymOwnerLoggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  if (userExist && loggedIn && !membershipUpgraded) {
    return <Navigate to={routes.HOME} />;
  } else {
    return children;
  }
};

const Routes = () => {
  useLogPageEvent();
  return (
    <RoutesDom>
      <Route
        path={routes.DASHBOARD_MEMBERS}
        element={
          <ProtectedRouteMembers>
            <DashboardMembers />
          </ProtectedRouteMembers>
        }
      />
      <Route
        path={routes.DASHBOARD_GYM_OWNERS}
        element={
          <ProtectedRouteGyms>
            <DashboardGymOwners />
          </ProtectedRouteGyms>
        }
      />
      <Route path={routes.HOME} element={<Home />} />
      <Route path={routes.LOGIN_TESTING} element={<LoginTesting />} />
      <Route
        path={routes.CHECKOUT}
        element={
          <ProtectedRouteCheckout>
            <Checkout />
          </ProtectedRouteCheckout>
        }
      />
      <Route
        path={`${routes.SIGNUP_GYM_OWNERS}/:tokenValue`}
        element={<SignUpGymOwner />}
      />
    </RoutesDom>
  );
};

export default Routes;
